import "../styles/base.scss";

const onelinkUrl = "https://plantguru.onelink.me/fqkD/q5z2l9he";
const linkSelectors = ".rates__get, .app-store, .button-try button";

document.addEventListener('DOMContentLoaded', function () {
  (function () {
    // capi setting
    var capi = new Capi();

    if (window.smart_script_onelinl_url) {
      capi.setOnelinkUrl(smart_script_onelinl_url);
    }

    capi.isStaging(false);
    capi.setButton(linkSelectors);
    capi.init();
  })();
});



const rates = document.querySelector(".rates");
const closeRatesButton = document.querySelector(".rates__close");
closeRatesButton.addEventListener("click", (event) => {
  rates.style.display = "none";
});

const linkSelectorsArr = linkSelectors.split(", ");
linkSelectorsArr.forEach((selector) => {
  const elements = document.querySelectorAll(selector);

  if (elements.length > 0) {
    elements.forEach((el) => {
      el.addEventListener("click", () => {
        window.location.href = onelinkUrl;
      });
    });
  }
});
